@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(Open Sans Light ), local(Open Sans-Light), url("open-sans-latin-300.9135b47a.woff2") format("woff2"), url("open-sans-latin-300.d1d46cd9.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local(Open Sans Light italic), local(Open Sans-Lightitalic), url("open-sans-latin-300italic.b17ab1cb.woff2") format("woff2"), url("open-sans-latin-300italic.547dcfdf.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Open Sans Regular ), local(Open Sans-Regular), url("open-sans-latin-400.6de481cb.woff2") format("woff2"), url("open-sans-latin-400.ffb6cdf9.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local(Open Sans Regular italic), local(Open Sans-Regularitalic), url("open-sans-latin-400italic.3a64a403.woff2") format("woff2"), url("open-sans-latin-400italic.b3b72514.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local(Open Sans SemiBold ), local(Open Sans-SemiBold), url("open-sans-latin-600.093ebd82.woff2") format("woff2"), url("open-sans-latin-600.d8f059c7.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: local(Open Sans SemiBold italic), local(Open Sans-SemiBolditalic), url("open-sans-latin-600italic.f3a5962c.woff2") format("woff2"), url("open-sans-latin-600italic.a340f217.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Open Sans Bold ), local(Open Sans-Bold), url("open-sans-latin-700.eb066dac.woff2") format("woff2"), url("open-sans-latin-700.8eb597cd.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local(Open Sans Bold italic), local(Open Sans-Bolditalic), url("open-sans-latin-700italic.6fb5136d.woff2") format("woff2"), url("open-sans-latin-700italic.ff637321.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local(Open Sans ExtraBold ), local(Open Sans-ExtraBold), url("open-sans-latin-800.38e3249b.woff2") format("woff2"), url("open-sans-latin-800.c0846917.woff") format("woff");
}

@font-face {
  font-family: Open Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: local(Open Sans ExtraBold italic), local(Open Sans-ExtraBolditalic), url("open-sans-latin-800italic.13692009.woff2") format("woff2"), url("open-sans-latin-800italic.b087bdea.woff") format("woff");
}

@page {
  margin: 0;
}

body {
  margin: 0;
}

.sheet {
  box-sizing: border-box;
  page-break-after: always;
  margin: 0;
  position: relative;
  overflow: hidden;
}

body.A3 .sheet {
  width: 297mm;
  height: 419mm;
}

body.A3.landscape .sheet {
  width: 420mm;
  height: 296mm;
}

body.A4 .sheet {
  width: 210mm;
  height: 296mm;
}

body.A4.landscape .sheet {
  width: 297mm;
  height: 209mm;
}

body.A5 .sheet {
  width: 148mm;
  height: 209mm;
}

body.A5.landscape .sheet {
  width: 210mm;
  height: 147mm;
}

body.letter .sheet {
  width: 216mm;
  height: 279mm;
}

body.letter.landscape .sheet {
  width: 280mm;
  height: 215mm;
}

body.legal .sheet {
  width: 216mm;
  height: 356mm;
}

body.legal.landscape .sheet {
  width: 357mm;
  height: 215mm;
}

.sheet.padding-10mm {
  padding: 10mm;
}

.sheet.padding-15mm {
  padding: 15mm;
}

.sheet.padding-20mm {
  padding: 20mm;
}

.sheet.padding-25mm {
  padding: 25mm;
}

@media screen {
  body {
    background: #e0e0e0;
  }

  .sheet {
    background: #fff;
    margin: 5mm auto;
    box-shadow: 0 .5mm 2mm #0000004d;
  }
}

@media print {
  body.A3.landscape {
    width: 420mm;
  }

  body.A3, body.A4.landscape {
    width: 297mm;
  }

  body.A4, body.A5.landscape {
    width: 210mm;
  }

  body.A5 {
    width: 148mm;
  }

  body.letter, body.legal {
    width: 216mm;
  }

  body.letter.landscape {
    width: 280mm;
  }

  body.legal.landscape {
    width: 357mm;
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, menu, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section {
  display: block;
}

[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*# sourceMappingURL=index.8a324141.css.map */
